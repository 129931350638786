import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useDispatch } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
  const dispatcher = useDispatch();
  const ctxApplicant = useSelector(state => state.stepSlice.data.bonus.richiedente[0]);
  const ctxCredit = useSelector(state => state.stepSlice.data.bonus.credito);
  const ctxIsee = useSelector(state => state.stepSlice.data.bonus.isee);
  const ctxPref = useSelector(state => state.stepSlice.data.preferenze);

  const [loading, setLoading] = useState(false);
  const [appl, setAppl] = useState(ctxApplicant);
  const [credit, setCredit] = useState(ctxCredit);
  const [isee, setIsee] = useState(ctxIsee);
  const [pref, setPref] = useState(ctxPref.bonus);

  const onNext = async () => {
    const data = {
      richiedente: [appl],
      credito: credit,
      isee: isee,
      preferenze: pref
    };

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Bonus", data: { richiedente: data.richiedente, credito: data.credito, isee: isee } }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "bonus", data: pref }));

    props.send(data);
  }

  const save = async () => {
    const data = {
      richiedente: [appl],
      credito: credit,
      isee: isee,
      preferenze: { bonus: pref }
    };

    setLoading(true);
    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Bonus", data: { richiedente: data.richiedente, credito: data.credito, isee: isee } }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", internalId: "bonus", data: pref }));

    setLoading(false);
  }

  const update = (data) => {
    if (data.id === "preferenze")
      setPref(data.data[0]);
    else if (data.id === "Richiedente")
      setAppl(data.data[0]);
    else if (data.id === "credito")
      setCredit(data.data);
    else {
      setIsee(data.data)
    }
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            <div style={{ position: "fixed", top: "50%", left: "50%", zIndex: "10000000000000" }}>
              <Loader loading={loading} />
            </div>

            <SummaryCard
              type="richiedente"
              cardTitle={`${ctxApplicant?.anagrafica?.nome} ${ctxApplicant?.anagrafica.cognome}`}
              mod={true}
              update={update}
              id={"richiedente"}
              taxID={appl?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={appl}
            >
              <FormBoxField title="Nome" value={appl?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={appl?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={appl?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={appl?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={appl?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={appl?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={appl?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={appl?.contatti.telefono} />
              <FormBoxField title="Email" value={appl?.contatti.email} />
              <FormBoxField title="Carta di identità " value={appl?.documenti.carta_di_identita} />
            </SummaryCard>

            {isee.length > 0 && (
              <SummaryCard
                type="isee"
                cardTitle={`${isee[0]?.valore_isee}`}
                mod={true}
                update={update}
                id={"isee"}
                taxID={""}
                service={props.info.code}
                registryData={isee[0]}
              >
                <FormBoxField title="Valore ISEE" value={isee[0]?.valore_isee} />
                <FormBoxField title="Scadenza ISEE" value={isee[0]?.scadenza_isee} />
                <FormBoxField title="Anno protocollo" value={isee[0]?.anno_protocollo} />
                <FormBoxField title="Numero protocollo" value={isee[0]?.numero_protocollo} />
                <FormBoxField title="Codice filiale protocollo" value={isee[0]?.codice_filiale_protocollo} />
                <FormBoxField title="Certificato isee" value={isee[0]?.certificato_isee} />
              </SummaryCard>
            )}

            <SummaryCard
              type="Dati per l'accredito"
              cardTitle={`${credit?.intestatario}`}
              mod={true}
              update={update}
              id={"credito"}
              taxID={""}
              service={props.info.code}
              registryData={credit}
            >
              <FormBoxField title="Intestatario" value={credit?.intestatario} />
              <FormBoxField title="Banca" value={credit?.banca} />
              <FormBoxField title="Filiale" value={credit?.filiale} />
              <FormBoxField title="Indirizzo" value={credit?.indirizzo} />
              <FormBoxField title="Codice IBAN" value={credit?.codice_iban} />
              <FormBoxField title="Documento IBAN" value={credit?.iban} />
            </SummaryCard>

            <SummaryCard
              type="Preferenze"
              mod={true}
              cardTitle={`Preferenze di servizio`}
              service="DBE"
              registryData={ctxPref.bonus}
              choiceData={[
                {
                  "options": [
                    {
                      "id": 1,
                      "value": "Nessun bonus attivo al momento"
                    }
                  ],
                  "title": [
                    "BONUS",
                    "TIPOLOGIA"
                  ],
                  "type": "Select"
                }
              ]
              }
              update={update}>
              <FormBoxField title="Tipologia" value={ctxPref.bonus.tipologia || "Non compilato"} />
            </SummaryCard>


            <NextBtnForm
              last={true}
              send={onNext}
              back={props.back}
              noSave={false}
              saveRequest={save}
              disabled={false} />


            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;