
function NextBtnForm(props) {
    return (
        <div className="flex-btn-centering" style={{ padding: "10px" }}>

            <button
                type="submit"
                style={{ padding: "0.5rem 2.5rem 0.5rem 2.5rem", color: "var(--primary)" }}
                data-bs-validate="validate"
                onClick={props.back}
            >

                <span className="text-button-sm">Indietro</span>

            </button>
            {
                props?.noSave ||
                <button
                    type="button"
                    className={`btn btn-outline-primary bg-white `}
                    onClick={props.saveRequest}>
                    <span className="text-button-sm t-primary">Salva Richiesta</span>
                </button>
            }
            {
                !props.last ?
                    <button
                        type="submit"
                        className={`${`btn btn-primary ${props.disabled ? "disabled" : ""}`} `}
                        style={{ padding: "0.5rem 2.5rem 0.5rem 2.5rem" }}
                        data-bs-validate="validate"
                        onClick={props.next}
                    >
                        <span className="text-button-sm">Avanti</span>
                    </button> :
                    <button
                        type="submit"
                        className={`${"btn btn-primary  "} `}
                        style={{ padding: "0.5rem 2.5rem 0.5rem 2.5rem" }}
                        data-bs-validate="validate"
                        onClick={props.send}
                    >
                        {
                            props.id === "PTI" ?
                                <span className="text-button-sm">Genera F24</span>
                                :
                                <span className="text-button-sm">Invia</span>
                        }
                    </button>
            }
        </div>

    )
}

export default NextBtnForm;