import React, { useEffect, useState } from "react";
import CustomerFormBox from "../../UI-component/registryForms/CustomerFormBox";
import SideList from "../../UI-component/SideList";
import ErrorAlert from "../../UI-component/ErrorAlert";
import { useSelector, useDispatch } from "react-redux";
import stepSlice from "../../context/StepsContext";
import NextBtnForm from "../../UI-component/NexBtnForm";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import Loader from "../../UI-component/Loader";

export default function SuoloServiziPagamentoDatiPersonali(props) {
  const dispatcher = useDispatch();

  const taxPayerCtx = useSelector(state => state.stepSlice.data.type_richiedente);
  const isStopped = useSelector(state => state.stepSlice.data.stopped);

  const [error, setError] = useState(false);

  const [typeData, setTypeData] = useState(taxPayerCtx/* ?? props.info?.testData.type_richiedente*/);
  const [loading, setLoading] = useState(false);

  const errorChecker = () => {
    if (!typeData || typeData?.length <= 0)
      setError(true);
    else
      setError(false);
  }

  useEffect(() => {
    errorChecker();
  }, [typeData])

  const onNext = async () => {
    if (typeData.length > 0)
      dispatcher(stepSlice.actions.dynamicSave({ id: "type_richiedente", data: typeData[0] }));

    setLoading(true);
    await props.next();

    setLoading(false);
  }

  const update = (data) => {
    if (typeof data === "object") {
      if (data.removing) {
        setTypeData(undefined);
        return;
      }
      setTypeData(data.data);
    }

    if (Array.isArray(data)) {
      setTypeData([]);
    }
  }

  const save = async () => {
    if (typeData.length > 0)
      dispatcher(stepSlice.actions.dynamicSave({ id: "type_richiedente", data: typeData[0] }));

    const data = {
      type_richiedente: typeData
    }

    setLoading(true);

    await props.save(data);

    setLoading(false);
  }

  return (
    <div className="row">
      <SideList
        infoList={[
          { element: "Richiedente", id: "#applicant-info" }
        ]}
      />
      <div className="col-12 col-lg-8 offset-lg-1">
        {
          <ErrorAlert errors={[{ name: "Richiedente", id: "#richiedente-info" }]} err={error || isStopped} />
        }

        <CustomerFormBox
          id="richiedente"
          cards={typeData?.length > 0 ? [typeData] : []}
          tp={props.info.testData?.last_type}
          type={true}
          description="Informazioni su di te"
          title="Richiedente"
          service={props.info.code}
          update={update}
          prefData={props.info?.testData.type_richiedente}
          generic={props.info.testData?.generico?.giuridico?.type_richiedente}
        />


        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
          <Loader loading={loading} />
        </div>


        <NextBtnForm
          saveRequest={save}
          next={onNext}
          back={props.back}
          disabled={error || isStopped}
        />
        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>

  );
}
