import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import Loader from "../../../UI-component/Loader";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { uniqueIdBuilder } from "../../../util/Dynamics";
import PopupSelectField from "../../../UI-component/ui-el/PopupSelectField";

function Specific(props) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data.preferenze.matrimonio);
    const ctxApplicant = useSelector(state => state.stepSlice.data.richiedente);
    const ctxApplicant2 = useSelector(state => state.stepSlice.data.richiedente_2);
    const prefs = useSelector(state => state.stepSlice.data.preferenze);

    const [marriage, setMarriage] = useState(ctx.tipo_matrimonio);
    const [date, setDate] = useState(ctx.m_data);
    const [marca, setMarca] = useState({
        data_marca_1: ctx.data_marca_1,
        numero_1: ctx.numero_1,
        data_marca_2: ctx.data_marca_2,
        numero_2: ctx.numero_2
    });


    const [loading, setLoading] = useState(false);
    const [error, setError] = useState({
        marca: false,
        marriage: false,
        date: false
    });

    const onChangeMarriage = (e) => {
        setMarriage(e.target.value);
    }

    const onChangeDate = (e) => {
        setDate(e.target.value);
    }

    const onChangeMarca = (e) => {
        setMarca(prev => {
            const obj = structuredClone(prev);
            obj[e.target.id] = e.target.value;

            return { ...obj }
        });
    }

    const onNext = () => {

        dispatcher(stepSlice.actions.dynamicSave({
            id: "Preferenze", internalId: "matrimonio", data: { tipo_matrimonio: marriage, m_data: date, ...marca }
        }));

        props.next();
    }

    const onError = () => {
        setError(prev => {
            return {
                ...prev,
                marriage: marriage !== "" ? false : true,
                marca: marca.numero_1 === "" || marca.data_1 === "" ? true : false,
                date: date === "" ? true : false
            }
        });

    }


    const save = async () => {
        const obj = structuredClone(prefs);
        obj.matrimonio = { tipo_matrimonio: marriage, m_data: date, ...marca };

        const data = {
            richiedente: ctxApplicant?.length > 0 ? [{ ...ctxApplicant[0], selected: true }] : [],
            richiedente_2: ctxApplicant2?.length > 0 ? [{ ...ctxApplicant2[0], selected: true }] : [],
            preferenze: obj
        }

        setLoading(true);

        await props.save(data);

        dispatcher(stepSlice.actions.dynamicSave({
            id: "Preferenze", internalId: "matrimonio", data: { tipo_matrimonio: marriage, m_data: date, ...marca }
        }));


        setLoading(false);
    }


    useEffect(() => {
        onError();
    }, [marriage, marca, date]);

    return (
        <div className="row">
            <SideList
                infoList={[
                    { element: "Tipo di Matrimonio", id: "#report-author" },
                    { element: "Data del matrimonio (presumibile)", id: "#report-author" },
                    { element: "Marca da bollo 1", id: "#report-author" },
                    { element: "Marca da bollo 2", id: "#report-author" },
                ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">

                        <ErrorAlert errors={[{ name: "Tipo di Matrimonio", id: "#isee-info" }]} err={error.marriage} />
                        <ErrorAlert errors={[{ name: "Marca da bollo 1", id: "#isee-info" }]} err={error.marca} />
                        <ErrorAlert errors={[{ name: "Data del matrimonio (presumibile)", id: "#isee-info" }]} err={error.date} />

                        <br></br>

                        <SummaryCard
                            type="Tipo di matrimonio*"
                            description={""}>
                            {

                                <PopupSelectField
                                    defaultValue={marriage}
                                    onChange={onChangeMarriage}
                                    id={"marriage"}
                                    fieldTitle={"Tipo di Matrimonio"}
                                    options={[
                                        "Civile",
                                        "Concordatario",
                                        "Acattolico",
                                        "Altro",
                                    ]}
                                    description=""
                                    key={uniqueIdBuilder()} />
                            }
                        </SummaryCard>

                        <SummaryCard type="Data del matrimonio (presumibile)*">
                            <PopupTextField
                                defaultValue={date}
                                onChange={onChangeDate}
                                id="date" fieldType="date"
                                fieldTitle="Data"
                                description=""
                            />

                        </SummaryCard>

                        <SummaryCard type="Marca da bollo 1*">
                            <PopupTextField
                                defaultValue={marca.numero_1}
                                onChange={onChangeMarca}
                                id="numero_1"
                                fieldType="text"
                                fieldTitle="Numero"
                                description=""
                                regex="^[0-9]{14}$"
                                max="14"
                                min="14" />
                            <PopupTextField
                                defaultValue={marca.data_marca_1}
                                onChange={onChangeMarca}
                                id="data_marca_1"
                                fieldType="date"
                                fieldTitle="Data"
                                description=""
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </SummaryCard>

                        <SummaryCard type="Marca da bollo 2">
                            <PopupTextField
                                defaultValue={marca.numero_2}
                                onChange={onChangeMarca}
                                id="numero_2"
                                fieldType="text"
                                fieldTitle="Numero"
                                description=""
                                regex="^[0-9]{14}$"
                                max="14"
                                min="14" />
                            <PopupTextField
                                defaultValue={marca.data_marca_2}
                                onChange={onChangeMarca}
                                id="data_marca_2"
                                fieldType="date"
                                fieldTitle="Data"
                                min={new Date().toISOString().split('T')[0]}
                                description="" />

                        </SummaryCard>
                    </div>
                </div>
                <div style={{ transform: "translateY(10%)" }}>
                    <Loader loading={loading} />
                </div>

                <NextBtnForm
                    next={onNext}
                    back={props.back}
                    noSave={false}
                    saveRequest={save}
                    disabled={error.marriage || error.marca || error.date}
                />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup isError={true} />
                }
            </div>
        </div>
    )
}

export default Specific;