import React, { useEffect, useState } from "react";
import SummaryCard from "../../UI-component/summary/SummaryCard";
import FormBoxField from "../../UI-component/ui-el/FormBoxField";
import { useDispatch, useSelector } from "react-redux";
import { titleExtractor, uniqueIdBuilder, titleNormalizer, checkIrregulars } from "../../util/Dynamics";
import NextBtnForm from "../../UI-component/NexBtnForm";
import stepSlice from "../../context/StepsContext";
import SuccessfullPopup from "../../UI-component/SuccessfullPopup";
import Loader from "../../UI-component/Loader";

export default function ServiziPagamentoRiepilogo(props) {
  const dispatcher = useDispatch();
  const data = useSelector(state => state.stepSlice.data);
  const [stopper, setStopper] = useState(true);
  const [testData, setTestData] = useState(data);
  const [loading, setLoading] = useState(false);

  const update = (data) => {
    //todo: check
    setTestData(prev => {
      const obj = structuredClone(prev);

      if (data.id === "Richiedente")
        obj["type_richiedente"] = data.data;

      obj[titleNormalizer(data.id)] = data.data;

      if (data.id === "Beneficiario") {
        for (let i = 0; i < obj.savedBen.length; i++) {
          if (obj.savedBen[i].id === data.data[0].id)
            obj.savedBen[i] = data.data[0];
        }
      }

      return { ...prev, ...obj };
    });
  }

  // It filters customer preferences summary
  // based on the data that resides in the ctx
  // and the specified configuration
  const preferenceFilter = () => {
    let comps = [];
    let normalizedParentTitle = "";
    let outerTitle = titleNormalizer(props.info.data.components[0].title[0]);

    for (let i of props.info.data.components) {
      if (i.type !== "MultiText") {
        const val = testData.preferenze[outerTitle][titleNormalizer(i.title[1])
          .normalize('NFD').replace(/[\u0300-\u036f]/g, '')];

        if (val) {
          if (Array.isArray(val)) {
            for (let y of val) {
              comps.push(
                <FormBoxField
                  title={titleExtractor(i.title[1])}
                  value={y || "Non Compilato"}
                  key={uniqueIdBuilder()} />);
            }
          } else {
            comps.push(
              <FormBoxField
                title={titleExtractor(i.title[1])}
                value={val || "Non Compilato"}
                key={uniqueIdBuilder()} />);
          }
        }

        if (i.title[1] === "SCUOLA") {
          const internal = testData.preferenze[outerTitle]["classe"];

          comps.push(
            <FormBoxField
              title={"Classe"}
              value={internal || "Non Compilato"}
              key={uniqueIdBuilder()} />);

        }
      }
      else {
        for (let j of i?.child_title) {
          const key = titleNormalizer(j).normalize('NFD').replace(/[\u0300-\u036f]/g, '');

          normalizedParentTitle = titleNormalizer(i.title[1]);

          comps.push(
            <FormBoxField
              title={j}
              value={testData.preferenze[outerTitle][normalizedParentTitle][key] || "Non Compilato"}
              key={uniqueIdBuilder()} />)
        }
      }
    }

    return (
      <div>
        {comps}
      </div>);
  }

  // Renders customer preferences summary
  // based on the data that resides in the ctx
  const preferencesRenderer = () => {
    return (
      <SummaryCard mod={true}
        cardTitle="Preferenze di servizio"
        type="Preferenze"
        choiceData={props.info.data.components}
        key={uniqueIdBuilder()}
        service={props.info.code}
        service_name={props.info.service_name}
        registryData={testData}
        update={update}>
        {preferenceFilter()}
      </SummaryCard>
    )
  }

  const toRemove = (el) => {
    const title = titleExtractor(el).replace("isee", "ISEE");

    return title;
  }

  // renders customer summary (isee, applicant,beneficiary)
  // based on the data that resides in the ctxS
  const customerRenderer = (key) => {
    //TODO FILTRARE CONFIG PER SERVIZIO TIPO FERMATA NON DEVE ESSERE PRESENTE SU STELLANELLO
    return (
      <div key={uniqueIdBuilder()}>
        {
          testData[key] !== undefined &&
          testData[key].map(itm => {
            if (Array.isArray(itm)) {
              for (let item of itm) {
                if (item?.selected) {
                  const cardTitle = key !== "isee" ? (item?.anagrafica?.nome || "") + " " + (item?.anagrafica?.cognome || "") : "Dati ISEE";
                  // console.log("customerRenderer", testData[key], key, item)

                  return (
                    <SummaryCard
                      taxID={item?.anagrafica?.codice_fiscale}
                      mod={true}
                      cardTitle={cardTitle}
                      type={key.toLowerCase() === "type_richiedente" ? "richiedente" : key.toLowerCase()}
                      key={uniqueIdBuilder()}
                      service={props.info.code}
                      service_name={props.info.service_name}
                      registryData={item}
                      update={update}
                    >
                      {
                        Object.keys(item).map(el => {
                          if (el.toLowerCase().includes("base64")) return;

                          if (el === "selected" || el === "id") return;

                          if (key === "isee") {
                            return <FormBoxField
                              title={el.includes("isee") ? toRemove(el) : titleExtractor(el)}
                              value={item[el] ? item[el] : "Non Compilato"}
                              key={uniqueIdBuilder()} />
                          } else {
                            return Object.keys(item[el]).map(internalItm => {
                              if (internalItm.toLowerCase().includes("base64"))
                                return;

                              let inID = checkIrregulars(internalItm);

                              return <FormBoxField
                                title={inID === "" ? titleExtractor(internalItm) : inID}
                                value={itm[el][internalItm] ? itm[el][internalItm] : "Non Compilato"}
                                key={uniqueIdBuilder()} />
                            })
                          }
                        })
                      }
                    </SummaryCard>
                  )
                }
              }

            } else {
              if (itm?.selected) {
                // console.log("customerRenderer aa", itm, key)

                const cardTitle = key !== "isee" ? (itm?.anagrafica?.nome || "") + " " + (itm?.anagrafica?.cognome || "") : "Dati ISEE";
                return (
                  <SummaryCard
                    update={update}
                    registryData={itm}
                    taxID={itm?.anagrafica?.codice_fiscale}
                    mod={true}
                    cardTitle={cardTitle}
                    type={key.toLowerCase() === "type_richiedente" ? "richiedente" : key.toLowerCase()}
                    key={uniqueIdBuilder()}
                    service={props.info.code}
                    service_name={props.info.service_name}>
                    {
                      Object.keys(itm).map(el => {
                        if (el.toLowerCase().includes("base64")) return;


                        if (el === "selected" || el === "id") return;

                        if (key === "isee") {
                          return <FormBoxField
                            title={el.includes("isee") ? toRemove(el) : titleExtractor(el)}
                            value={itm[el] ? itm[el] : "Non Compilato"}
                            key={uniqueIdBuilder()} />
                        } else {
                          return Object.keys(itm[el]).map(internalItm => {
                            if (internalItm.toLowerCase().includes("base64"))
                              return;

                            let inID = checkIrregulars(internalItm);

                            return <FormBoxField
                              title={inID === "" ? titleExtractor(internalItm) : inID}
                              value={itm[el][internalItm] ? itm[el][internalItm] : "Non Compilato"}
                              key={uniqueIdBuilder()} />

                          })
                        }
                      })
                    }
                  </SummaryCard>
                )
              }
            }
          })
        }
      </div>
    )
  }

  //Checks if the box is optional or not
  //if it does have the optional title, it means
  //that the box is not gonna be rendered
  const checkOptional = (key) => {
    // const opt = props.info.optData.components[2]?.optional_title;
    const opt = props.info.optData.components;
    let isVisible = false;
    let idx = 0;

    for (let i of opt) {
      idx += 1;

      if (titleNormalizer(i.title) === key) {
        isVisible = i?.optional_title;
        break;
      }
    }

    if (isVisible)
      return <div key={idx}></div>;

    return customerRenderer(key);
  }

  //Renders a specified box depending on the configuration
  const rendererController = () => {
    // console.log("renderController", data);
    return Object.keys(data).map(key => {
      //!Array.isArray istead of !== [] check
      if (data[key] !== undefined || !Array.isArray(data[key])) {
        switch (key) {
          case "isee":
            return checkOptional(key);
          case "richiedente":
            return checkOptional(key);
          case "beneficiario":
            return checkOptional(key);
          case "type_richiedente":
            return checkOptional(key);
          case "preferenze":
            return preferencesRenderer("Preferenze");
          default:
            break;
        }
      }
    })
  }

  const idFinder = (id) => {
    let formattedId = id;

    switch (id) {
      case "richiedente":
        formattedId = "Richiedente";
        break;
      case "beneficiario":
        formattedId = "Beneficiario";
        break;
      case "isee":
        formattedId = "ISEE";
        break;
      default:
        break;
    }

    return formattedId;
  }

  const onNext = async () => {
    setLoading(true);
    //todo: check
    let data = {};

    for (let i of Object.keys(testData)) {
      if (i === "preferenze") {
        data[i] = testData[i];
        continue;
      }

      if (testData[i]?.length > 0) {
        if (testData[i][0]) {
          const id = titleNormalizer(idFinder(i));
          data[id] = testData[i];
        }
      }
    }

    const comps = [];

    for (let i of props.info.optData.components)
      comps.push(titleNormalizer(i.title));

    if (props.info.code === "PSP") {
      comps.push("type_richiedente")
    }

    for (let i of Object.keys(testData)) {
      if (comps.includes(i)) {
        if (testData[i]) {
          dispatcher(stepSlice.actions.dynamicSave({ id: idFinder(i), data: testData[i][0] }));
        }
      }
    }


    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: props.info.service_name, data: data.preferenze[props.info.service_name] }));


    let tmp = data;
    data = {
      ...tmp,
      savedBen: tmp.savedben
    }

    delete data.savedben;

    await props.send(data);
    setLoading(false);
  }

  const save = async () => {
    let data = {};

    for (let i of Object.keys(testData)) {
      if (i === "preferenze") {
        data[i] = testData[i];
        continue;
      }

      if (testData[i]?.length > 0) {
        if (testData[i][0])
          data[titleNormalizer(idFinder(i))] = testData[i];
      }
    }

    const comps = [];

    for (let i of props.info.optData.components)
      comps.push(titleNormalizer(i.title));

    if (props.info.code === "PSP") {
      comps.push("type_richiedente")
    }

    for (let i of Object.keys(testData)) {
      if (comps.includes(i)) {
        if (testData[i]) {
          dispatcher(stepSlice.actions.dynamicSave({ id: idFinder(i), data: testData[i][0] }));
        }
      }
    }


    dispatcher(stepSlice.actions.dynamicSave({ id: "Preferenze", internalId: props.info.service_name, data: data.preferenze[props.info.service_name] }));

    let tmp = data;
    data = {
      ...tmp,
      savedBen: tmp.savedben
    }

    delete data.savedben;
    setLoading(true);

    await props.save(data);

    setLoading(false);
  }

  useEffect(() => {
    if (stopper) {
      preferencesRenderer();
      setStopper(false);
    }
  }, [testData])


  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">
            {
              rendererController()
            }
          </div>
        </div>
        <div style={{ position: "fixed", top: "50%", left: "50%", transform: "translate(-55%,-50%)", zIndex: "10000" }}>
          <Loader loading={loading} />
        </div>
        <NextBtnForm
          saveRequest={save}
          send={onNext}
          back={props.back}
          last={true} />

        {
          props.saved &&
          <SuccessfullPopup />
        }

        {
          props.fail &&
          <SuccessfullPopup isError={true} />
        }
      </div>
    </div>
  );
}
