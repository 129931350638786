import React, { useEffect, useState } from "react";
import SideList from "../../../UI-component/SideList";
import ErrorAlert from "../../../UI-component/ErrorAlert";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import PopupTextField from "../../../UI-component/ui-el/PopupTextField";
import { PaymentApi } from "../../../Apis/PaymentApi";
import Loader from "../../../UI-component/Loader";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import { useDispatch, useSelector } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { uniqueIdBuilder } from "../../../util/Dynamics";
import PopupSelectField from "../../../UI-component/ui-el/PopupSelectField";

function Specific(props) {
    const dispatcher = useDispatch();
    const ctx = useSelector(state => state.stepSlice.data.avviso);
    const ctxPayment = useSelector(state => state.stepSlice.data.pagamento);


    const [show, setShow] = useState(false);
    const [invoiceData, setInvoiceData] = useState({});
    const [code, setCode] = useState(ctx ?? "");
    const [securityCode, setSecutiryCode] = useState("")
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [blockErr, setBlockErr] = useState(true);
    const [payments, setPayments] = useState([]);
    const [payment, setPayment] = useState(ctxPayment);

    const onChange = (e) => {
        if (e.target.id.includes("sicurezza")) {
            setSecutiryCode(e.target.value);
            return;
        }

        // console.log(e.target.value);

        setCode(e.target.value);
    }

    const getInvoiceInformation = async (cd = false) => {
        // console.log(!cd ? false : true);

        // console.log(!cd ? code : cd);

        try {
            setLoading(true);
            const res = await PaymentApi.getInvoiceInfoById(!cd ? code : cd);

            if (!res?.success) {
                setError(true);
                setLoading(false);
                setInvoiceData({});
                setShow(false);
                setBlockErr(true);
                return;
            }

            setInvoiceData(res.data.invoice_data);
            setShow(true);
            setLoading(false);
            setError(false);
            setBlockErr(false);
        } catch (err) {
            console.log(err);
            setLoading(false);
            setInvoiceData({});
            setShow(false);
            setError({
                code: false,
                securityCode: false
            });
            setBlockErr(true);
        }
    }

    const onNext = () => {
        dispatcher(stepSlice.actions.dynamicSave({ id: "code", data: code }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "fattura", data: invoiceData }));
        dispatcher(stepSlice.actions.dynamicSave({ id: "pagamento", data: payment }));

        props.next();
    }

    const onError = () => {
        const err = {
            code: false,
        }
        if (code === "")
            err.code = true;

        setError(err);
    }

    const paymentListBuilder = async () => {

        const pays = await PaymentApi.getPayments();

        if (!pays.success) {
            return [];
        }

        const paymentList = [];

        if (pays?.data.length > 0) {
            pays?.data.map((itm, idx) => {
                if (itm?.paymentCode.split("-")[0] === "PCL") {
                    if (itm.pay)
                        paymentList.push(itm);
                }
            })
        }

        setPayments(paymentList);
    }

    const onChangePayment = async (e) => {
        const paymentFound = payments.filter(itm => {
            return itm.iuv === e.target.options[e.target.selectedIndex].text;
        })

        if (paymentFound.length > 0) {
            await getInvoiceInformation(paymentFound[0].iuv)

            setPayment(prev => {
                const pay = paymentFound[0];

                return {
                    ...prev,
                    fattura: pay.paymentCode,
                    data_emissione: pay?.date,
                    metodo: pay?.paymentMethod || "Non trovato",
                    importo: pay.amount || "Non trovato",
                    iuv: pay.iuv || "Non trovato",
                    link: pay.link
                }
            })

            setCode(paymentFound[0].iuv);


        }
    }

    useEffect(() => {
        if (code !== "")
            getInvoiceInformation();

        paymentListBuilder();
    }, []);

    return (
        <div className="row">
            <SideList
                infoList={[
                    { element: "Codice avviso (IUV)", id: "#report-author" },
                ]} />

            <div className="col-12 col-lg-8 offset-lg-1">
                <div className="steppers-content" aria-live="polite">
                    <div className="it-page-sections-container">

                        <ErrorAlert errors={[{ name: "Codice avviso (IUV)", id: "#isee-info" }]} err={blockErr} />

                        {/* <SummaryCard
                            type="Avvisi di pagamento*"
                            description={"Scegli il pagamento"}>
                            {

                                <PopupSelectField
                                    defaultValue={payment?.iuv}
                                    onChange={onChangePayment}
                                    id={"code_id"}
                                    fieldTitle={"Codice avviso IUV"}
                                    options={payments.map(itm => itm.iuv)}
                                    description="Seleziona il codice di riferimento"
                                    key={uniqueIdBuilder()} />
                            }
                        </SummaryCard> */}

                        <br></br>
                        <SummaryCard
                            type="Avviso di pagamento*"
                            description={"Seleziona o inserisci il codice avviso"}>

                            <SummaryCard
                                type=""
                                description={""}>
                                {

                                    <PopupSelectField
                                        defaultValue={payment?.iuv}
                                        onChange={onChangePayment}
                                        id={"code_id"}
                                        fieldTitle={"Codice avviso (IUV) esistente"}
                                        options={payments.map(itm => itm.iuv)}
                                        description="Seleziona un codice avviso esistente"
                                        key={uniqueIdBuilder()} />
                                }
                            </SummaryCard>
                            <PopupTextField
                                defaultValue={code || payment?.iuv}
                                fieldType="text"
                                id="nome"
                                fieldTitle="Codice avviso (IUV)*"
                                description="Inserisci il codice avviso"
                                max="18"
                                straightErr={error}
                                onChange={onChange}
                            />
                            <button
                                className="internal-btn"
                                onClick={() => { getInvoiceInformation() }}>
                                Ricerca fattura</button>
                        </SummaryCard>
                        {
                            show &&
                            <>
                                <SummaryCard type="Fattura">
                                    <FormBoxField title="Codice IUV" value={code || payment?.iuv} />
                                    <FormBoxField title="Data emissione" value={invoiceData?.data_emissione} />
                                    <FormBoxField title="Data scadenza" value={invoiceData?.data_scadenza} />
                                    <FormBoxField title="Ente" value={invoiceData?.ente} />
                                    <FormBoxField title="Codice Fiscale ente" value={invoiceData?.codice_fiscale_ente} />
                                    <FormBoxField title="Intestatario" value={invoiceData?.intestatario} />
                                    <FormBoxField title="Codice Fiscale Intestatario" value={invoiceData?.codice_fiscale_intestatario} />
                                    <FormBoxField title="Importo dovuto" value={invoiceData?.importo_dovuto} />
                                    <FormBoxField title="Importo già pagato" value={invoiceData?.importo_gia_pagato} />
                                </SummaryCard>
                            </>
                        }
                    </div>
                </div>
                <div style={{ transform: "translateY(10%)" }}>
                    <Loader loading={loading} />
                </div>

                <NextBtnForm
                    next={onNext}
                    back={props.back}
                    noSave={true}
                    disabled={blockErr} />
                {
                    props.saved &&
                    <SuccessfullPopup />
                }

                {
                    props.fail &&
                    <SuccessfullPopup isError={true} />
                }
            </div>
        </div>
    )
}

export default Specific;