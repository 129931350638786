import React, { useState } from "react";
import { useSelector } from "react-redux";
import SummaryCard from "../../../UI-component/summary/SummaryCard";
import NextBtnForm from "../../../UI-component/NexBtnForm";
import FormBoxField from "../../../UI-component/ui-el/FormBoxField";
import SuccessfullPopup from "../../../UI-component/SuccessfullPopup";
import { useDispatch } from "react-redux";
import stepSlice from "../../../context/StepsContext";
import Loader from "../../../UI-component/Loader";

function Summary(props) {
  const dispatcher = useDispatch();
  const ctxCemeteryApplicant = useSelector(state => state.stepSlice.data.cimitero.concessionario[0]);
  const ctxCemeteryDeceased = useSelector(state => state.stepSlice.data.cimitero.defunto[0]);
  const ctxCemeteryPref = useSelector(state => state.stepSlice.data.preferenze);

  const [loading, setLoading] = useState(false);
  const [appl, setAppl] = useState(ctxCemeteryApplicant);
  const [deac, setDeac] = useState(ctxCemeteryDeceased);

  const onNext = async () => {
    const data = {
      concessionario: [appl],
      defunto: [deac],
      preferenze: ctxCemeteryPref
    };

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Cimitero", data: { concessionario: data.concessionario, defunto: data.defunto } }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", data: data.preferenze }));

    props.send(data);
  }

  const save = async () => {
    const data = {
      concessionario: [appl],
      defunto: [deac],
      preferenze: ctxCemeteryPref
    };

    setLoading(true);
    await props.save(data);

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Cimitero", data: { concessionario: data.concessionario, defunto: data.defunto } }));

    dispatcher(stepSlice.actions
      .dynamicSave({ id: "Preferenze", data: data.preferenze }));

    setLoading(false);
  }

  const update = (data) => {
    if (data.id === "concessionario")
      setAppl(data.data[0]);
    else
      setDeac(data.data[0]);
  }

  return (
    <div className="row">
      <div className="col-12 col-lg-8 offset-lg-2">
        <div className="steppers-content" aria-live="polite">
          <div className="it-page-sections-container">

            <div style={{ position: "fixed", top: "50%", left: "50%", zIndex: "10000000000000" }}>
              <Loader loading={loading} />
            </div>

            {/* type="richiedente"
                cardTitle={`${applicant?.anagrafica?.nome} ${applicant?.anagrafica?.cognome}`}
                mod={true}
                update={update}
                id={"Richiedente"}
                taxID={applicant?.anagrafica.codice_fiscale}
                service={props.info.code}
                registryData={applicant} */}
            <SummaryCard
              type="Concessionario"
              cardTitle={`${ctxCemeteryApplicant?.anagrafica?.nome} ${ctxCemeteryApplicant?.anagrafica.cognome}`}
              mod={true}
              update={update}
              id={"concessionario"}
              taxID={appl?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={appl}
            >
              <FormBoxField title="Nome" value={appl?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={appl?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={appl?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={appl?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={appl?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Indirizzo residenza" value={appl?.indirizzi.residenza} />
              <FormBoxField title="Comune residenza" value={appl?.indirizzi.domicilio} />
              <FormBoxField title="Telefono" value={appl?.contatti.telefono} />
              <FormBoxField title="Email" value={appl?.contatti.email} />
              <FormBoxField title="Carta di identità " value={appl?.documenti.carta_di_identita} />
            </SummaryCard>

            <SummaryCard
              type="Defunto"
              cardTitle={`${ctxCemeteryDeceased?.anagrafica?.nome} ${ctxCemeteryDeceased?.anagrafica.cognome}`}
              mod={true}
              update={update}
              id={"defunto"}
              taxID={deac?.anagrafica.codice_fiscale}
              service={props.info.code}
              registryData={deac}
            >
              <FormBoxField title="Nome" value={deac?.anagrafica.nome} />
              <FormBoxField title="Cognome" value={deac?.anagrafica.cognome} />
              <FormBoxField title="Codice Fiscale" value={deac?.anagrafica.codice_fiscale} />
              <FormBoxField title="Data di nascita" value={deac?.anagrafica.data_di_nascita} />
              <FormBoxField title="Luogo di nascita" value={deac?.anagrafica.luogo_di_nascita} />
              <FormBoxField title="Data di morte" value={deac?.anagrafica.data_di_morte} />
              <FormBoxField title="Luogo di morte" value={deac?.anagrafica.luogo_di_morte} />
              <FormBoxField title="Paternità" value={deac?.anagrafica.paternita} />
              <FormBoxField title="Certificato di morte" value={deac?.documenti.certificato_di_morte} />
            </SummaryCard>

            <SummaryCard
              type="Preferenze"
              cardTitle={`Preferenze di servizio`} >
              <FormBoxField title="Arrivo" value={ctxCemeteryPref.cimitero?.arrivo.value} />
              <FormBoxField title="Tipo di sepoltura" value={ctxCemeteryPref.cimitero?.tipo_di_sepoltura.value} />
              <FormBoxField title="Cimitero" value={ctxCemeteryPref.cimitero?.cimitero.value} />
              <FormBoxField title="Casellario" value={ctxCemeteryPref.cimitero?.casellario.value !== "" ? ctxCemeteryPref.cimitero?.casellario.value : "Non applicato"} />
              <FormBoxField title="Loculo" value={ctxCemeteryPref.cimitero?.loculo.value !== "" ? ctxCemeteryPref.cimitero?.loculo.value : "Non Applicato"} />
              <FormBoxField title="Durata" value={ctxCemeteryPref.cimitero?.durata.value} />
            </SummaryCard>
            <NextBtnForm
              send={onNext}
              back={props.back}
              saveRequest={save}
              noSave={false}
              last={true}
            />
            {
              props.saved &&
              <SuccessfullPopup />
            }

            {
              props.fail &&
              <SuccessfullPopup isError={true} />
            }
          </div>
        </div>
      </div>
    </div>
  )
}


export default Summary;